<template>
  <div class="mainPage">
    <div class="flex justify-between">
      <p class="pageTitle"><a-icon @click="back" type="left" class="goBack"/>Stock base table management-edit</p>
    </div>
    <div class="pageWrap">
      <a-descriptions layout="vertical" :column="9" bordered>
        <a-descriptions-item label="Country">
          {{data.countryCode}}
        </a-descriptions-item>
        <a-descriptions-item label="ship model ID">
          {{data.recallModelId}}
        </a-descriptions-item>
        <a-descriptions-item label="color">
          {{data.recallColor}}
        </a-descriptions-item>
        <a-descriptions-item label="ship SKU ID">
          {{data.recallSkuId}}
        </a-descriptions-item>
        <a-descriptions-item label="ship SKU number">
          {{data.recallSku}}
        </a-descriptions-item>
        <a-descriptions-item label="warehouse ID">
          {{data.warehouse}}
        </a-descriptions-item>
        <a-descriptions-item label="warehouse location">
          {{data.warehouseAddress}}
        </a-descriptions-item>
        <a-descriptions-item label="Available stock">
          <a-input-number v-model="data.stock" :min="0" :max="999999" :step="1" :precision="0" style="min-width: 100px"/>
        </a-descriptions-item>
        <a-descriptions-item label="shipping method">
          {{data.shippingMethod}}
        </a-descriptions-item>
      </a-descriptions>
      <div style="text-align:center;margin-top: 150px;">
          <a-button class="mr24" @click="handleCancel">
            Back
          </a-button>
          <a-button type="primary" @click="onSubmit">
            Save
          </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import stockMixin from './stockMixin'

export default {
  mixins: [stockMixin],
  data () { 
    return {
      data: {
        stock: null
      },
    }
  },
  created () {
    this.id = this.$route.params.id
    this.getData(this.id)
  },
  methods: {
    handleCancel () { 
      this.$confirm({
        title: 'Are you sure you want to give up saving?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk: this.back,
      });
    },
    async getData (id) {
      const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/getStockBaseInfo', {
        id
      })
      this.data = res.data
    },
    onSubmit () {
      const onOk = async () => { 
        await this.$http.post('/platform/admin/ticketAdminManage/v1/updateStock', {
          id: this.id,
          stock: this.data.stock
        })
        this.back()
      }
      this.$confirm({
        title: 'Are you sure to save?',
        content: '',
        okText: 'Yes',
        cancelText: 'No',
        onOk,
      });
    }
  }
}
</script>
